/* eslint-disable no-undef */
export const URL_STATICS=process.env.REACT_APP_URL_STATICS;
export const URL_STATICS_LOGOS_CANALES=process.env.REACT_APP_URL_STATICS_LOGOS_CANALES
export const URL_STATICS_LOGOS_CANALES_PNG=process.env.REACT_APP_URL_STATICS_LOGOS_CANALES_PNG
export const URL_STATICS_LOGOS_CANALES_SVG=process.env.REACT_APP_URL_STATICS_LOGOS_CANALES_SVG
export const URL_STATICS_CATALOGO=process.env.REACT_APP_URL_STATICS_CATALOGO
export const URL_STATICS_LICENCIAS_SVG=process.env.REACT_APP_URL_STATICS_LICENCIAS_SVG
export const URL_API = "https://api.catalogo.biodiversidad.co";
export const URL_API_UICN = "https://api.riesgo.biodiversidad.co";
export const URL_API_DATOS = "https://api.busquedas.biodiversidad.co";
export const URL_API_PARTICIPATION = process.env.REACT_APP_URL_API_PARTICIPATION;
export const TOKEN = "soufhaishfauisd";
export const USER = process.env.REACT_APP_USER;
export const URL_SIB_COLOMBIA = process.env.REACT_URL_SIB_COLOMBIA;
export const URL_LISTAS = "https://listas.biodiversidad.co/";
export const URL_COLECCIONES = "https://colecciones.biodiversidad.co/";
export const URL_PORTAL = "https://biodiversidad.co/data";
export const URL_CATALOGO = "https://colecciones.biodiversidad.co/";
export const URL_NEW_API_DEV = process.env.REACT_APP_URL_NEW_API_DEV;
export const URL_IMG_UICN = process.env.REACT_APP_URL_IMG_UICN;
export const THUMBNAIL_IMAGE_SERVICE = process.env.REACT_THUMBNAIL_IMAGE_SERVICE


